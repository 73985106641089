import React, { ButtonHTMLAttributes, FC, ReactElement, useMemo } from "react";
import classNames from "classnames";

import Icon, { IconName } from "@components/common/Icon";
import Spinner from "@components/common/Spinner";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: IconName;
  iconClassName?: string;

  leadingIcon?: IconName;
  leadingIconClassName?: string;

  children?: ReactElement | string;
  childrenClassName?: string;

  loading?: boolean;

  size?: "md" | "xl";
  color?:
    | "primary"
    | "secondary"
    | "green"
    | "orange"
    | "white"
    | "yellow"
    | "link-primary"
    | "link-secondary"
    | "link-orange"
    | "link-green"
    | "plain-black"
    | "plain-orange"
    | "plain-green"
    | "plain-secondary";
}

const Button: FC<ButtonProps> = ({
  icon,
  iconClassName,

  leadingIcon,
  leadingIconClassName,

  children,
  childrenClassName,

  loading = false,

  size = "md",
  color = "primary",

  className,
  ...props
}) => {
  const variant = useMemo(() => {
    if (color.includes("link")) {
      return "link";
    }

    if (color.includes("plain")) {
      return "plain";
    }

    return "pressing";
  }, [color]);

  return (
    <button
      type="button"
      className={classNames(className, "btn", {
        [`btn-${variant}`]: variant,
        [`btn-${color}`]: color,
        [`btn-${size}`]: size,
        "with-icon": !!icon,
      })}
      {...props}
    >
      {leadingIcon && (
        <Icon name={leadingIcon} className={classNames("btn-icon", leadingIconClassName)} />
      )}
      <span className={classNames("btn-children", childrenClassName)}>
        {!loading ? children : <Spinner />}
      </span>
      {icon && <Icon name={icon} className={classNames("btn-icon", iconClassName)} />}
    </button>
  );
};

export default Button;
