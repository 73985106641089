/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef } from "react";

import classnames from "classnames";
import { Link, PageProps, navigate } from "gatsby";

import Button from "@components/common/Button";
import Icon from "@components/common/Icon";

import useLocalStorageState from "use-local-storage-state";

import Image1 from "@static/images/products/preview/1.png";
import Image2 from "@static/images/products/preview/2.png";
import Image3 from "@static/images/products/preview/3.png";
import Image4 from "@static/images/products/preview/4.png";
import Image5 from "@static/images/products/preview/5.png";
import Image6 from "@static/images/products/preview/6.png";

const links = [
  {
    to: "/shop/nighttime",
    image: Image1,
  },
  {
    to: "/shop/recover",
    image: Image2,
  },
  {
    to: "/shop/uplift",
    image: Image3,
  },
  {
    to: "/shop/relax",
    image: Image4,
  },
  {
    to: "/shop/hers",
    image: Image5,
  },
  {
    to: "/shop/his",
    image: Image6,
  },
];

const ProductDropDownMenu: FC<{
  toggleMenu: (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement>) => void;
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  location: PageProps["location"];
}> = ({ toggleMenu, isActive, setIsActive, location }) => {
  const [bannerIsOpen] = useLocalStorageState<boolean>("isOpenPromoCodeBanner");
  const prevPathRef = useRef(location?.pathname);

  useEffect(() => {
    if (location?.pathname !== prevPathRef.current) {
      setIsActive(false);
      prevPathRef.current = location?.pathname;
    }
  }, [location?.pathname, setIsActive]);

  const toggleActive = useCallback((): void => {
    setIsActive(!isActive);
  }, [isActive, setIsActive]);

  const onMouseEnter = (): void => {
    setIsActive(true);
  };

  return (
    <div
      className={classnames("products-items", { "is-active": isActive })}
      onMouseEnter={onMouseEnter}
    >
      <Button
        icon="chevron"
        size="xl"
        color="link-secondary"
        className={classnames("products-drop-down", { "is-active": isActive })}
        iconClassName={classnames("button-icon", { "is-active": isActive })}
        onClick={toggleActive}
      >
        Find Your Groove
      </Button>

      <div
        className={classnames("products-body", { "is-active": isActive })}
        style={{
          paddingTop:
            bannerIsOpen &&
            (process.env.GATSBY_ANNOUNCEMENT_BANNER ||
              (window && window.location.pathname.includes("anti-anxiety-calming-bed")))
              ? "58px"
              : "12px",
        }}
      >
        <div className="body-wrapper">
          <Button
            icon="chevron"
            size="xl"
            color="link-secondary"
            className={classnames("products-drop-down", { "is-active": isActive })}
            onClick={toggleActive}
            iconClassName={classnames("button-icon", { "is-active": isActive })}
          >
            Find Your Groove
          </Button>
          <div className="product-cards" onClick={toggleMenu}>
            {links.map(({ image, to }, index) => (
              <Button
                key={index}
                onClick={() => {
                  // gaSelectItem(product);
                  void navigate(to);
                }}
                size="xl"
                color="link-secondary"
                className="product-card-header"
              >
                <img alt={index.toString()} src={image} width={200} height={200} loading="eager" />
              </Button>
            ))}
          </div>

          {/* <Link to="/shop" className="all-products-link" onClick={toggleMenu}>
            Browse All Products <Icon name="chevron" />
          </Link> */}
          <div className="all-products">
            <Link to="/shop">
              <Button
                color="white"
                icon="sm-arrow-right"
                leadingIcon="wattering-can"
                leadingIconClassName="product-button-icon"
                className="product-button"
              >
                All Products
              </Button>
            </Link>
            <Link to="/shop/bestsellers">
              <Button
                color="white"
                icon="sm-arrow-right"
                leadingIcon="start-shield"
                leadingIconClassName="product-button-icon"
                className="product-button"
              >
                Best Sellers
              </Button>
            </Link>
            <Link to="/shop/flower">
              <Button
                color="white"
                icon="sm-arrow-right"
                leadingIcon="vertical-flower"
                leadingIconClassName="product-button-icon"
                className="product-button"
              >
                Flower
              </Button>
            </Link>
            <Link to="/shop/edible">
              <Button
                color="white"
                icon="sm-arrow-right"
                leadingIcon="gummies"
                leadingIconClassName="product-button-icon"
                className="product-button"
              >
                Gummies
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDropDownMenu;
