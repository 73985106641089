/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";

import classNames from "classnames";

import { gaBeginCheckout } from "@analytics/google";
import {
  addToCart,
  closeCart,
  removeFromCart,
  selectCartItems,
  selectIsCartOpen,
  selectOldTotalPrice,
  selectTotalPrice,
} from "@store/cart";

import { Button } from "@components/common";
import { BedCheckoutItems } from "@components/checkout/CheckoutSummary";

import getUpsellProducts from "@helpers/getUpsellProducts";

import ProgressBar from "@components/common/ProgressBar";
import getProducts from "@helpers/products/getProducts";
import CartItem from "./item/CartItem";
import CartNotifiers from "./notifiers/CartNotifiers";
import UpsellCartItem from "./UpsellCartItem/UpsellCartItem";

export const delivery = {
  MONTHLY: "Monthly (Most common)",
  EVERY_2_MONTHS: "2 Months",
  EVERY_3_MONTHS: "3 Months",
  EVERY_6_MONTHS: "6 Months",
  EVERY_12_MONTHS: "12 Months",
  NONE: "One time",
};

const Cart = () => {
  const dispatch = useDispatch();

  const isCartOpen = useSelector(selectIsCartOpen);
  const cartItems = useSelector(selectCartItems);
  const totalPrice = useSelector(selectTotalPrice);
  const oldTotalPrice = useSelector(selectOldTotalPrice);

  const upsellProducts = useMemo(() => getUpsellProducts(cartItems, true), [cartItems]);
  const freeProducts = useMemo(() => getProducts(undefined, ["NugFree", "PreRollFree"]), []);

  const upsellFiltered = upsellProducts
    .slice(0, 2)
    .filter((item, index, self) => index === self.findIndex((t) => t.id === item.id));

  const isSubscriptionProduct = useMemo(() => {
    return cartItems.some((item) => item.product.subscriptionType !== "NONE");
  }, [cartItems]);

  const [currentStep, setCurrentStep] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);

  const stepPrice = [40, 70, 100];

  useEffect(() => {
    if (typeof window !== "undefined" && typeof window.zE === "function") {
      if (isCartOpen) {
        window.zE("messenger", "hide");
      } else {
        window.zE("messenger", "show");
      }
    }
  }, [isCartOpen]);

  const addFreeProduct = (sku: string) => {
    const productToAdd = freeProducts.find((product) => product.sku === sku);

    const cartItem = {
      product: {
        ...productToAdd,
      },
      isFree: true,
      quantity: 1,
    };

    if (productToAdd && !cartItems.some((item) => item.product.id === productToAdd.id)) {
      dispatch(addToCart({ cartItems: [cartItem] }));
    }
  };

  const deleteFreeProduct = (sku: string) => {
    const productToDelete = freeProducts.find((product) => product.sku === sku);

    const cartItem = {
      product: {
        ...productToDelete,
      },
      isFree: true,
      quantity: 1,
    };

    if (productToDelete) {
      dispatch(removeFromCart({ cartItems: [cartItem] }));
    }
  };

  useEffect(() => {
    let newStep = 0;
    let remaining = 0;

    if (isSubscriptionProduct) {
      newStep = 1;
    }

    for (let i = 0; i < stepPrice.length; i++) {
      if (totalPrice >= stepPrice[i]) {
        newStep = i + 1;
      } else {
        remaining = stepPrice[i] - totalPrice;
        break;
      }
    }

    setCurrentStep(newStep);

    if (newStep === 0) {
      setRemainingAmount(stepPrice[0] - totalPrice);
    } else if (newStep === 1) {
      setRemainingAmount(stepPrice[1] - totalPrice);
    } else if (newStep === 2) {
      setRemainingAmount(stepPrice[2] - totalPrice);
    } else {
      setRemainingAmount(0);
    }
  }, [totalPrice, isSubscriptionProduct]);

  useEffect(() => {
    if (totalPrice < 70) {
      deleteFreeProduct("NugFree");
      deleteFreeProduct("PreRollFree");
    } else if (totalPrice < 100) {
      deleteFreeProduct("PreRollFree");
    }

    if (totalPrice >= 70) {
      addFreeProduct("NugFree");
    }

    if (totalPrice >= 100) {
      addFreeProduct("PreRollFree");
    }
  }, [totalPrice]);

  const handleCloseCart = () => {
    dispatch(closeCart());
  };

  const handleProceedToCheckout = () => {
    dispatch(closeCart());
    gaBeginCheckout({ value: totalPrice, cartItems });

    void navigate("/checkout");
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseCart);

    return () => document.removeEventListener("click", handleCloseCart);
  }, []);

  useEffect(() => {
    if (isCartOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => document.body.classList.remove("modal-open");
  }, [isCartOpen]);

  return (
    <React.Fragment>
      {isCartOpen && <div className="overlay" />}
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNames("cart", { "--is-open": isCartOpen })}
      >
        <div className="cart-header">
          <h3 className="cart-header__title">Shopping Cart</h3>

          <Button
            className="close-btn"
            icon="cross"
            color="link-secondary"
            iconClassName="close-btn-icon"
            aria-label="close-cart"
            onClick={handleCloseCart}
          />
        </div>

        <div className="cart-body">
          <CartNotifiers />

          <div className="cart-body-content">
            {cartItems.length > 0 && (
              <ProgressBar
                currentStep={currentStep}
                remainingAmount={+remainingAmount.toFixed(2)}
              />
            )}

            {cartItems
              .filter((i) => i.product.slug !== "anti-anxiety-calming-bed")
              .map((item, index) => (
                <CartItem key={index} cartItem={item} />
              ))}
          </div>
          {upsellFiltered.length && cartItems.length ? (
            <div className="cart-body-upsell">
              <h3>You may also be interested in</h3>
              <div
                className={`cart-body-upsell-items upsell-scroll ${
                  upsellFiltered.length === 1 && "center"
                }`}
              >
                {upsellFiltered.map((product) => (
                  <UpsellCartItem key={product.id} product={product} />
                ))}
              </div>
            </div>
          ) : (
            ""
          )}

          <BedCheckoutItems cartItems={cartItems} />
        </div>

        <div className="cart-footer">
          <div className="cart-footer__total">
            <span className="cart-footer__total-text">TOTAL:</span>
            <div>
              {!!oldTotalPrice && (
                <span className="cart-footer__total-old-price">${oldTotalPrice}</span>
              )}
              <span className="cart-footer__total-price">${totalPrice}</span>
            </div>
          </div>

          <Button disabled={cartItems.length === 0} onClick={handleProceedToCheckout}>
            Proceed To Checkout
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Cart;
