import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { trackAddedToCart } from "@analytics/klaviyo";
import { gaAddToCart, gaRemoveFromCart } from "@analytics/google";
import { getProductById } from "@helpers/products/getProducts";
import getProduct from "@helpers/products/getProduct";

import {
  AddToCartActionPayload,
  CartState,
  RemoveFromCartActionPayload,
} from "./types/cartSliceState";

import { addCartItems } from "./utils/items/addCartItems";
import { removeCartItems } from "./utils/items/removeCartItems";

import { applyPromos } from "./utils/promos/applyPromos";
import { areCartItemsEqual } from "./utils/common/areCartItemsEqual";

const initialState: CartState = {
  cartItems: [],
  isCartOpen: false,
  isCartLoaded: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    getItemsFromLocalStorage: (state) => {
      state.cartItems = JSON.parse(localStorage.getItem("cartItems") || "[]");
      // state.isCartLoaded = true;
    },

    getItemsFromURL: (state) => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const productsParam = decodeURIComponent(urlParams.get("products") || "[]");

        if (productsParam) {
          const cartItems = JSON.parse(productsParam);

          if (Array.isArray(cartItems)) {
            const newCartItems = cartItems.filter(
              (item) => !state.cartItems.find((cartItem) => areCartItemsEqual(cartItem, item))
            );

            const transformedCartItems = newCartItems.map((item) => {
              const product = getProductById(item.product.id);

              if (product) {
                const fullProduct = getProduct(
                  product.name,
                  product.subscriptionType,
                  product.packageSize
                );

                return {
                  ...item,
                  quantity: item.quantity || 1,
                  product: {
                    deliveryFrequency: product.subscriptionType,
                    ...item.product,
                    ...product,

                    ...fullProduct,
                  },
                };
              }

              return null;
            });

            const filteredCartItems = transformedCartItems.filter(Boolean);

            state.cartItems = [...state.cartItems, ...filteredCartItems];
            state.isCartLoaded = true;
          }
        }

        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      } catch (err) {
        console.error(err);
      }
    },

    /* Modal Actions
     ============== */
    openCart: (state) => {
      state.isCartOpen = true;
    },

    closeCart: (state) => {
      state.isCartOpen = false;
    },

    toggleCart: (state) => {
      state.isCartOpen = !state.isCartOpen;
    },

    /* Cart Actions
     ============== */
    addToCart: (state, action: PayloadAction<AddToCartActionPayload>) => {
      const addedCartItems = action.payload.cartItems;

      state.cartItems = addCartItems(state.cartItems, addedCartItems);
      state.cartItems = applyPromos(state.cartItems);

      trackAddedToCart(state.cartItems, addedCartItems);
      gaAddToCart(addedCartItems);

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    removeFromCart: (state, action: PayloadAction<RemoveFromCartActionPayload>) => {
      const deletedCartItems = action.payload.cartItems;
      const isRemoveProgressProduct = action.payload.isRemoveProgressProduct;

      state.cartItems = removeCartItems(state.cartItems, deletedCartItems, isRemoveProgressProduct);
      state.cartItems = applyPromos(state.cartItems);

      if (!state.cartItems.length) state.isCartOpen = false;

      gaRemoveFromCart(deletedCartItems);

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    clearCart: (state) => {
      state.cartItems = [];

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
  },
});

export default cartSlice;
